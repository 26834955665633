<template>
  <v-container class="pa-0">
    <top-bar title="광고 그룹" shadow/>
    <div class="adgroup-header">
      <div class="bidtime-button-container">
        <v-btn
            color="primary"
            class="blue--text"
            @click="$router.push({ name: 'BidTime' })"
            depressed
        >
          등급별 시간 설정
        </v-btn>
      </div>
      <div class="adgroup-header-content">
        <h1 class="campaign-name">{{ campaignName }}</h1>
        <v-switch v-model="isActive" @change="changeCampaignActive" color="green" hide-details/>
      </div>
    </div>
    <div class="adgroup-body">
      <p>캠페인 이름</p>
      <AdGroupItem
          v-for="data in adgroupData"
          :key="data.id"
          :properties="data"
          @errorIconClicked="openErrorModal"
      />
      <div v-if="adgroupData.length <= 0">
        <p>광고 그룹이 없습니다.</p>
      </div>
      <v-btn
          @click="paginate"
          class="more-button"
          :disabled="paginateURL === null"
      >
        더보기
      </v-btn>
    </div>

    <!-- 오류 도움말 Modal -->
    <v-dialog
        v-model="isErrorHelpModalOn"
        max-width="480"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          문제점 해결 도움말
        </v-card-title>
        <div v-if="errorTypes.isPrefixMissing">
          <v-card-subtitle class="text-h5">광고그룹 이름에 접두어가 없습니다.</v-card-subtitle>
          <v-card-text>
            올바른 광고 그룹 설정을 위해서는, <b>"_PC"</b>, <b>"_모바일"</b>의 접두어를
            그룹명 뒤에 붙이셔야 합니다.
          </v-card-text>
        </div>
        <div v-if="errorTypes.isPrefixMismatched">
          <v-card-subtitle>광고그룹 이름 접두어와 노출 매체 유형 설정이 올바르지 않습니다.</v-card-subtitle>
          <v-card-text>
            광고그룹 설정 → 고급옵션 → 매체에서 <b>노출 매체 유형 선택</b>을 선택해주세요.
          </v-card-text>
        </div>
        <div v-if="errorTypes.isPrefixWrong">
          <v-card-subtitle>접두어의 형식이 잘못되었습니다.</v-card-subtitle>
          <v-card-text>
            접두어는 <b>"_"</b> 기호를 중심으로 뒤에 있는것으로 구분짓고 있습니다. 이 값이 <b>"_PC"</b>, <b>"_모바일"</b>이 아니라면
            에러가 출력됩니다.
          </v-card-text>
        </div>
        <div v-if="errorTypes.isDisplayTargetSettedAll">
          <v-card-subtitle>노출 매체에서 "모두"는 사용하실 수 없습니다.</v-card-subtitle>
          <v-card-text>
            자동입찰 시스템에서는 <b>"_PC"</b>, <b>"_모바일"</b>만 사용 가능합니다.
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              @click="isErrorHelpModalOn = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 로딩중 Overlay -->
    <v-overlay :value="isLoading">
      <v-progress-circular
          :size="100"
          :width="10"
          color="purple"
          indeterminate
      />
    </v-overlay>
  </v-container>
</template>

<script>
// todo paginate 구현
import TopBar from "../components/top-bar";
import AdGroupItem from "../components/adgroup-item";
import config from "../config";
// import moment from "moment";

export default {
  name: "adgroup",
  components: {
    TopBar,
    AdGroupItem,
  },
  data: function () {
    return {
      isLoading: true,
      campaignName: sessionStorage.getItem("campaign-name"),
      isActive: (sessionStorage.getItem("campaign-is-active") === 'true'),
      isErrorHelpModalOn: false,
      errorTypes: {},
      paginateURL: null,
      adgroupData: [],
    }
  },
  methods: {
    paginate: async function () {
      if (this.paginateURL !== null) {
        this.isLoading = true;
        const paginatedDataIds = await this.$axios.get(this.paginateURL);
        const paginatedData = await Promise.all(paginatedDataIds.data.results.map(async id => {
          const fetchedData = await this.fetchData(id.origin_adgroup_option);
          fetchedData.relationships = id;
          return fetchedData;
        }));
        this.adgroupData = this.adgroupData.concat(paginatedData)
        this.paginateURL = paginatedDataIds.data.next;
        this.isLoading = false;
      }
    },
    fetchData: async function (adgroupId) {
      const campaignId = sessionStorage.getItem("campaign-id");
      const uri = `/markerta/adgroup/${adgroupId}/?campaign-id=${campaignId}`;
      const {data: fetchedData} = await this.$axios.get(uri);
      return fetchedData;
    },
    // fetchTargetData: async function (adgroupId) {
    //   const uri = `/markerta/adgroup-time/?adgroup=${adgroupId}`;
    //   const {data: fetchedData} = await this.$axios.get(uri);
    //   if (fetchedData.count <= 0) {
    //     return false;
    //   }
    //   return fetchedData.results.reduce((acc, cur) => {
    //     const current = moment();
    //     const from = moment(`${cur.day_of_week} ${cur.display_from}`, "e HH:mm:ss");
    //     const to = moment(`${cur.day_of_week} ${cur.display_to}`, "e HH:mm:ss");
    //     console.log(from, to);
    //     if (acc) {
    //       return true;
    //     } else {
    //       return current.isAfter(from) && current.isBefore(to);
    //     }
    //   }, false);
    // },
    changeCampaignActive: async function (toggle) {
      await this.$axios.patch(`/markerta/campaign/${sessionStorage.getItem("campaign-option-id")}/`, {is_active: toggle});
    },
    openErrorModal: function(occurredErrors) {
      this.errorTypes = occurredErrors;
      this.isErrorHelpModalOn = true;
    }
  },
  created: async function () {
    const campaignId = sessionStorage.getItem("campaign-id");
    this.paginateURL = `${config.API_ADDRESS}markerta/adgroupid/?campaign-id=${campaignId}`;
    await this.paginate();
  }
}
</script>

<style scoped>
.adgroup-header,
.adgroup-body {
  padding: 8px 16px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  background-color: #FFFFFF;
}

.adgroup-body {
  margin-top: 16px;
}

.adgroup-header .bidtime-button-container {
  display: flex;
  flex-direction: row-reverse;
}

.adgroup-header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.adgroup-header-content .campaign-name {
  font-size: 24px;
}

.more-button {
  width: 100%;
  margin: auto auto;
}
</style>
