<template>
  <v-container class="pa-0">
    <top-bar title="키워드 그룹"/>
    <div class="keyword-header">
      <div class="keyword-header-content">
        <div class="keyword-header-content-container">
          <h1 class="adgroup-name">{{ currentAdGroup ? currentAdGroup.adgroup_id.name.split("_")[0] : "로딩중" }}</h1>
          <AdGroupDisplayTypeChip :display-target="displayTarget"/>
        </div>
        <v-switch v-model="currentAdGroup.is_active" :disabled="!currentAdGroup" @change="changeAdgroupActive"
                  color="green" hide-details/>
      </div>
      <v-alert v-if="!isShowTimes" class="mt-3" type="warning">
        <div>
          현재 시간에서 타겟 시간대에 해당되지 않아 자동 입찰이 진행되지 않습니다.
          <br/>
          자동으로 입찰되길 원하신다면, <a href="https://searchad.naver.com/" target="_blank">네이버 광고시스템</a>에 접속하여 변경하신 뒤 "캠페인 목록 > 광고계정 동기화" 버튼을 눌러주세요.
        </div>
      </v-alert>
    </div>
    <v-tabs v-model="currentTab" @change="onTabChange" fixed-tabs>
      <v-tabs-slider color="gray"/>
      <v-tab :disabled="isPcTabDisabled" class="black--text tab-item">PC</v-tab>
      <v-tab :disabled="isMobileTabDisabled" class="black--text tab-item">모바일</v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <!-- PC Tab -->
      <v-tab-item class="keyword-item-cont">
        <KeywordItem
            v-for="keywordData in keywordList.pc"
            :key="keywordData.id + 'pc'"
            :keyword-data="keywordData"
            :edit-mode="editMode"
        />
        <div v-if="keywordList.pc.length <= 0">
          <p>키워드가 없습니다.</p>
        </div>
        <v-btn @click="paginate" class="more-button" :disabled="paginateURL.pc === null">
          더보기({{ keywordList.pc.length }}/{{ totalKeywordCount.pc }})
        </v-btn>
      </v-tab-item>

      <!-- Mobile Tab -->
      <v-tab-item class="keyword-item-cont">
        <KeywordItem
            v-for="keywordData in keywordList.mobile"
            :key="keywordData.id + 'mobile'"
            :keyword-data="keywordData"
            :edit-mode="editMode"
            :mobile="true"
        />
        <div v-if="keywordList.mobile.length <= 0">
          <p>키워드가 없습니다.</p>
        </div>
        <v-btn @click="paginate" class="more-button" :disabled="paginateURL.mobile === null">
          더보기({{ keywordList.mobile.length }}/{{ totalKeywordCount.mobile }})
        </v-btn>
      </v-tab-item>
    </v-tabs-items>

    <!-- 배너 Sticky Menu -->
    <div class="sticky-menu-container">
      <KeywordMultipleEditor
          v-if="editMode === 'multiple'"
          v-model="multiple"
          @selectAllClicked="toggleAll"
      />
      <div v-if="editMode === 'singular'" class="sticky-buttons">
        <v-btn color="primary" class="blue--text sticky-button" :disabled="isSaveOnProgress" @click="onSave">변경사항 저장
        </v-btn>
        <v-btn color="primary" class="blue--text sticky-button" :disabled="isSaveOnProgress" @click="changeEditMode">
          일괄수정
        </v-btn>
      </div>
      <div v-if="editMode === 'multiple'" class="sticky-buttons">
        <v-btn color="green" class="sticky-button" :disabled="isSaveOnProgress" @click="onSave">일괄저장</v-btn>
        <v-btn color="error" class="sticky-button" :disabled="isSaveOnProgress" @click="changeEditMode">취소</v-btn>
      </div>
    </div>

    <!-- 로딩중 Overlay -->
    <v-overlay :value="isLoading">
      <v-progress-circular
          :size="100"
          :width="10"
          color="purple"
          indeterminate
      />
    </v-overlay>


    <!-- 동기화 재시도 시간 오류 Modal -->
    <v-dialog
        v-model="saveStatus.isSaveSuccess"
        max-width="360"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          저장 완료
        </v-card-title>
        <v-card-text class="gray--text">
          저장에 성공하였습니다. &gt;F5&lt;버튼을 누르거나 페이지를 새로 고침해주세요.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              @click="saveStatus.isSaveSuccess = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 체크 안됨 Snackbar -->
    <v-snackbar
        v-model="snackBarVisibility.nothingChecked"
    >
      아무 항목도 체크되어있지 않습니다. 저장할 항목을 체크해주세요.
      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackBarVisibility.nothingChecked = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- 저장 실패시 알림 Snackbar -->
    <v-snackbar
        v-model="saveStatus.isSaveFailed"
    >
      {{ saveStatus.failedReason }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="saveStatus.isSaveFailed = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import config from "../config"
import KeywordItem from "../components/keyword-item"
import KeywordMultipleEditor from "../components/keyword-multiple-editor";
import AdGroupDisplayTypeChip from "../components/adgroup-display-type-chip"
import TopBar from "../components/top-bar"
import AutoNumeric from "autonumeric";

export default {
  name: "keyword",
  components: {
    KeywordItem,
    TopBar,
    KeywordMultipleEditor,
    AdGroupDisplayTypeChip,
  },
  data: function () {
    return {
      currentTab: null,
      editMode: "singular",
      isLoading: true,
      isSaveOnProgress: false,
      paginateURL: {
        pc: "",
        mobile: ""
      },
      keywordList: {
        pc: [],
        mobile: [],
      },
      totalKeywordCount: {
        pc: 0,
        mobile: 0,
      },
      adGroups: {
        pc: null,
        mobile: null,
      },
      snackBarVisibility: {
        nothingChecked: false,
        autoBidDisabled: false,
      },
      saveStatus: {
        isSaveSuccess: false,
        isSaveFailed: false,
        failedReason: "",
      },
      multiple: {
        isBidding: false,
        maxBidding: 70,
        targetPosition: 0,
        weight: 10,
      },
      isShowTimes: false,
    }
  },
  computed: {
    isPcTabDisabled: function () {
      return this.editMode === 'multiple' || this.adGroups.pc === null;
    },
    isMobileTabDisabled: function () {
      return this.editMode === 'multiple' || this.adGroups.mobile === null;
    },
    displayTarget: function () {
      return sessionStorage.getItem('adgroup-display-target');
    },
    currentAdGroup: function () {
      if (this.currentTabStr === "P") {
        return this.adGroups.pc;
      }
      return this.adGroups.mobile;
    },
    currentKeywordList: function () {
      if (this.currentTabStr === "P") {
        return this.keywordList.pc;
      }
      return this.keywordList.mobile;
    },
    currentTabStr: function () {
      switch (this.currentTab) {
        case 0:
          return "P";
        case 1:
          return "M";
      }
      return "";
    }
  },
  methods: {
    loadAdGroup: async function (adgroupId) {
      const campaignId = sessionStorage.getItem("campaign-id");
      const uri = `/markerta/adgroup/${adgroupId}/?campaign-id=${campaignId}`;
      const {data: adGroupData} = await this.$axios.get(uri);
      return adGroupData;
    },
    loadAdGroupTime: async function (adgroupId) {
      const uri = `/markerta/adgroup-time/?adgroup=${adgroupId}`;
      const {data: adGroupTime} = await this.$axios.get(uri);
      return adGroupTime.results.reduce((acc, cur) => {
        return acc | cur.is_visible;
      }, false) || adGroupTime.count == 0;

    },
    getNextKeywordData: async function (url) {
      const {data: keywordIdContainer} = await this.$axios.get(url);
      const {count: keywordCount} = keywordIdContainer;
      const {results: keywordIds} = keywordIdContainer;
      const {next: nextUrl} = keywordIdContainer;
      const fetchedData = await Promise.all(keywordIds.map(async keywordId => {
        const fetchKeywordDataUrl = `/markerta/keyword/${keywordId.keyword_id}/`;
        const {data: keywordResponse} = await this.$axios.get(fetchKeywordDataUrl);
        const fetchPositionDataUrl = `${config.NAVER_API_API_ADDRESS}markerta/keyword/${keywordId.keyword_id}/position/` + (this.currentTabStr === "M" ? "?mobile=true" : "");
        const {data: positionResponse} = await this.$axios.get(fetchPositionDataUrl);
        return {
          ...keywordResponse,
          ...positionResponse,
          maxBidding: AutoNumeric.format(keywordResponse.maxBidding, {decimalPlaces: 0}),
          selected: false,
        }
      }));
      return {
        keywordCount,
        nextUrl,
        ...fetchedData,
      }
    },
    paginate: async function () {
      this.isLoading = true;
      let url = null;
      switch (this.currentTabStr) {
        case "P":
          url = this.paginateURL.pc;
          break;
        case "M":
          url = this.paginateURL.mobile;
          break;
      }
      const nextPageData = await this.getNextKeywordData(url);
      switch (this.currentTabStr) {
        case "P":
          this.totalKeywordCount.pc = nextPageData.keywordCount;
          this.paginateURL.pc = nextPageData.nextUrl;
          break;
        case "M":
          this.totalKeywordCount.mobile = nextPageData.keywordCount;
          this.paginateURL.mobile = nextPageData.nextUrl;
          break;
      }
      delete nextPageData.nextUrl;
      delete nextPageData.keywordCount;
      switch (this.currentTabStr) {
        case "P":
          this.keywordList.pc = this.keywordList.pc.concat(Object.values(nextPageData));
          break;
        case "M":
          this.keywordList.mobile = this.keywordList.mobile.concat(Object.values(nextPageData));
          break;
      }
      this.isLoading = false;
    },
    onTabChange: async function () {
      if (this.currentKeywordList.length <= 0) {
        this.isLoading = true;
        await this.paginate();
        this.isLoading = false;
      }
      window.localStorage.setItem("keywordCurrentTabIndex", this.currentTab);
    },
    changeAdgroupActive: async function (toggle) {
      await this.$axios.patch(`/markerta/adgroup/${this.currentAdGroup.id}/`, {is_active: toggle});
    },
    changeEditMode: function () {
      if (this.editMode === 'singular')
        this.editMode = 'multiple';
      else if (this.editMode === 'multiple')
        this.editMode = 'singular';
    },
    toggleAll: function (isSelectedAll) {
      this.currentKeywordList.forEach(data => {
        data.selected = isSelectedAll;
        console.log(data.id);
      });
    },
    onSave: async function () {
      this.isSaveOnProgress = true;
      // 현재 보고있는 탭에 따라 저장할 데이터를 가져온다.
      // PC 탭을 열고 있으면 PC 데이터만 저장하고, 모바일 탭을 열고 있으면 모바일 탭만 저장한다.
      const saveTargetData = (() => {
        switch (this.editMode) {
          case "singular":
            return this.currentKeywordList;
          case "multiple":
            return this.currentKeywordList.filter(data => data.selected);
        }
      })();
      console.log(saveTargetData);

      // 아무것도 선택하지 않았다면 에러 출력
      if (saveTargetData.length <= 0) {
        this.snackBarVisibility.nothingChecked = true;
        this.isSaveOnProgress = false;
        return;
      }

      // 저장 실행, 하나라도 실패하면 실패 토스트를 띄운다.
      const fetchTargetData = saveTargetData.map((data) => async () => {
        const saveData = (() => {
          switch (this.editMode) {
            case "singular":
              return {
                is_active: data.isBidding,
                max_bid_amt: parseInt(AutoNumeric.unformat(data.maxBidding)),
                target_rank: parseInt(data.targetPosition),
                weight: parseInt(AutoNumeric.unformat(data.weight)),
              };
            case "multiple":
              return {
                is_active: this.multiple.isBidding,
                max_bid_amt: parseInt(AutoNumeric.unformat(this.multiple.maxBidding)),
                target_rank: parseInt(this.multiple.targetPosition),
                weight: parseInt(AutoNumeric.unformat(this.multiple.weight)),
              };
          }
        })();
        await this.$axios.patch(`/markerta/keyword/${data.id}/`, saveData);
      });

      const isFetchSuccess = fetchTargetData.every((fetch) => {
        let isSuccess = true;
        fetch()
            .catch((err) => {
              // 저장 실패 토스트 출력
              isSuccess = false;
              this.saveStatus.failedReason = err;
              this.saveStatus.isSaveFailed = true;
              this.isSaveOnProgress = false;
            })
        return isSuccess;
      })

      if (isFetchSuccess) {
        // 저장 성공 토스트 출력
        this.saveStatus.isSaveSuccess = true;
        this.isSaveOnProgress = false;
      }
    },
  },
  mounted: async function () {
    // 광고그룹 불러오기
    const adGroupIds = JSON.parse(sessionStorage.getItem("selected-adgroup"));
    if (adGroupIds.origin_adgroup_option) {
      const originAdGroup = await this.loadAdGroup(adGroupIds.origin_adgroup_option);
      switch (originAdGroup.adgroup_id.display_target) {
        case "P":
          this.adGroups.pc = originAdGroup;
          break;
        case "M":
          this.adGroups.mobile = originAdGroup;
          break;
      }
      this.isShowTimes = await this.loadAdGroupTime(originAdGroup.adgroup_id.id);

    }
    if (adGroupIds.related_adgroup_option) {
      const relatedAdGroup = await this.loadAdGroup(adGroupIds.related_adgroup_option);
      console.log(relatedAdGroup);
      switch (relatedAdGroup.adgroup_id.display_target) {
        case "P":
          this.adGroups.pc = relatedAdGroup;
          break;
        case "M":
          this.adGroups.mobile = relatedAdGroup;
          break;
      }
      this.isShowTimes = await this.loadAdGroupTime(relatedAdGroup.adgroup_id.id);
    }

    // 초기 탭 확인
    if (this.adGroups.pc && this.adGroups.mobile || this.adGroups.pc) {
      this.currentTab = 0;
    } else if (this.adGroups.mobile) {
      this.currentTab = 1;
    }
    this.currentTab = parseInt(window.localStorage.getItem("keywordCurrentTabIndex"));

    // 초기 데이터 로딩
    if (this.adGroups.pc) {
      const {ncc_adgroup_id: pcAdGroupId} = this.adGroups.pc.adgroup_id;
      this.paginateURL.pc = this.adGroups.pc ? config.API_ADDRESS + `markerta/keyword/?adgroup-id=${pcAdGroupId}` : "";
    }
    if (this.adGroups.mobile) {
      const {ncc_adgroup_id: mobileAdGroupId} = this.adGroups.mobile.adgroup_id;
      this.paginateURL.mobile = this.adGroups.mobile ? config.API_ADDRESS + `markerta/keyword/?adgroup-id=${mobileAdGroupId}` : "";
    }
    await this.paginate();
    this.isLoading = false;
    console.log("mounted");
  },
}
</script>

<style scoped>
.tab-item:disabled {
  cursor: not-allowed;
}

.keyword-header {
  padding: 8px 16px 16px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
}

.keyword-header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.keyword-header-content .keyword-header-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.keyword-header-content .adgroup-name {
  font-size: 24px;
}

.keyword-item-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.more-button {
  width: 100%;
  margin: auto auto;
}

.sticky-menu-container {
  margin: 16px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  position: sticky;
  bottom: 8px;
}

.sticky-menu-container .sticky-buttons {
  display: flex;
  flex-direction: row;
}

.sticky-menu-container .sticky-buttons .sticky-button {
  margin: 8px;
  flex: 1;
}

</style>