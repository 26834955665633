<template>
  <v-app style="background-color: #f7f9fa; font-family: 'Spoqa Han Sans Neo', 'sans-serif'">
    <v-main>
      <router-view  />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>
