import Vue from "vue";
import axios from "axios";
import config from "../config";

const Axios = {
    install(Vue) {
        Vue.prototype.$axios = axios.create({
            baseURL: config.API_ADDRESS,
            xsrfCookieName: "csrftoken",
            xsrfHeaderName: "X-CSRFToken",
        });

        Vue.prototype.$axios.defaults.xsrfCookieName = "csrftoken";
        Vue.prototype.$axios.defaults.xsrfHeaderName = "X-CSRFToken";

        Vue.prototype.$axios.interceptors.request.use(
            (config) => {
                const accessToken = localStorage.getItem("access-token");
                if (accessToken) {
                    config.headers = Object.assign(
                        {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        config.headers
                    );
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        Vue.prototype.$axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                console.log("Vue", Vue);
                const requestConfig = error.config;
                const {status} = error.response;
                if (status === 401 || status === 403) {
                    // 서버에 Refresh Token 보내서 새로운 토큰들 받아오기
                    const oldRefreshToken = localStorage.getItem("refresh-token");
                    return axios({
                        url: config.API_ADDRESS + "accounts/token/refresh/",
                        method: "POST",
                        data: {refresh: oldRefreshToken},
                    }).catch((error) => {
                        localStorage.clear();
                        location.reload();
                        return Promise.reject(error);
                    }).then(response => {
                        // 받아온 토큰들을 로컬에 저장
                        const {access, refresh} = response.data;
                        localStorage.setItem("access-token", access);
                        localStorage.setItem("refresh-token", refresh);

                        // 헤더에 반영 후 Request 재시도
                        requestConfig.headers.Authorization = `Bearer ${access}`;
                        return axios(requestConfig);
                    });
                }
                return Promise.reject(error);
            }
        );
    },
};

Vue.use(Axios);

export default Axios;
