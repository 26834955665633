import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
import fontAwesome from "./plugins/fontawesome";
import router from "./router";
import App from "./App";
import './registerServiceWorker'

Vue.config.productionTip = false;
Vue.use(VueClipboard);
new Vue({
  vuetify,
  router,
  axios,
  fontAwesome,
  render: (h) => h(App),
}).$mount("#app");
