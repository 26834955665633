var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',{staticClass:"pa-0"},[_c('top-bar',{attrs:{"title":"회원가입","shadow":""}}),_c('v-layout',{attrs:{"column":""}},[_c('v-form',[_c('v-col',{staticClass:"pa-4"},[_vm._v(" 아이디 "),_c('validation-provider',{attrs:{"name":"아이디","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","label":"아이디","error-messages":errors,"dense":"","solo":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_vm._v(" 이메일 "),_c('validation-provider',{attrs:{"name":"이메일","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","label":"이메일","error-messages":errors,"dense":"","solo":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_vm._v(" 비밀번호 "),_c('validation-provider',{attrs:{"name":"비밀번호","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"한 개 이상의 영문자, 특수문자를 포함하여 주세요.","error-messages":errors,"dense":"","solo":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_vm._v(" 현장명 "),_c('validation-provider',{attrs:{"name":"현장명","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"예) 마케르타","error-messages":errors,"dense":"","solo":""},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}})]}}],null,true)}),_vm._v(" 담당자 이름 "),_c('validation-provider',{attrs:{"name":"담당자 이름","rules":"required|max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"홍길동","error-messages":errors,"dense":"","solo":""},model:{value:(_vm.managerName),callback:function ($$v) {_vm.managerName=$$v},expression:"managerName"}})]}}],null,true)}),_vm._v(" 담당자 휴대전화 "),_c('validation-provider',{attrs:{"name":"담당자 휴대전화","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"tel","label":"01012341234","error-messages":errors,"dense":"","solo":""},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"이용약관 동의","rules":{required: {allowFalse: false}}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"이용약관에 동의","error-messages":errors},model:{value:(_vm.eulaAgreed),callback:function ($$v) {_vm.eulaAgreed=$$v},expression:"eulaAgreed"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"개인정보처리방침 동의","rules":{required: {allowFalse: false}}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"개인정보처리방침 동의","error-messages":errors},model:{value:(_vm.privacyPolicyAgreed),callback:function ($$v) {_vm.privacyPolicyAgreed=$$v},expression:"privacyPolicyAgreed"}})]}}],null,true)})],1),_c('v-col',{attrs:{"align":"center"},on:{"click":function($event){$event.preventDefault();return _vm.signUp.apply(null, arguments)}}},[_c('v-btn',{attrs:{"depressed":"","disabled":invalid}},[_vm._v(" 확인 ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"360"},model:{value:(_vm.isRegisterFailed),callback:function ($$v) {_vm.isRegisterFailed=$$v},expression:"isRegisterFailed"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 에러 ")]),_c('v-card-text',{staticClass:"gray--text"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.isRegisterFailed = false}}},[_vm._v(" 확인 ")])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }