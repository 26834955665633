<template>
  <v-chip>
    <span v-if="displayTarget === 'A'">모두</span>
    <span v-if="displayTarget === 'P'">PC</span>
    <span v-if="displayTarget === 'M'">모바일</span>
  </v-chip>
</template>

<script>
export default {
  name: "adgroup-display-type-chip",
  props: ["displayTarget"],
}
</script>

<style scoped>

</style>