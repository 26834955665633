export default {
    API_ADDRESS: "https://markerta.the-compass.kr/api/",
    SYNC_API_ADDRESS: "https://markerta.the-compass.kr/api/",
    NAVER_API_API_ADDRESS: "https://markerta.the-compass.kr/api/",

    // // DEBUG
    // API_ADDRESS: "http://3.38.200.172/api/",
    // SYNC_API_ADDRESS: "http://3.38.200.172/api/",
    // NAVER_API_API_ADDRESS: "http://3.38.200.172/api/",
};
