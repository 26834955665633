<template>
  <v-container class="pa-0">
    <TopBar title="캠페인" hide-back my/>
    <div class="header">
      <div class="header-container">
        <div style="line-height: 1.2;">
          <span class="cost-title">최근 7일 집행비용</span><br/>
          <span class="cost-date">
            파워링크 광고
            ({{ moment().subtract('7', 'days').format('MM.DD') }}~{{ moment().format('MM.DD') }})
          </span>
        </div>
        <div>
          <v-btn
              color="primary"
              class="blue--text"
              @click="onSyncPressed"
              depressed
          >
            광고계정 동기화
          </v-btn>
        </div>
      </div>
      <div class="campaign-info-body">
        <p class="cost">{{ new Intl.NumberFormat().format(exhausted) }}원</p>
        <p class="bizmoney">
          잔여 비즈머니: {{ new Intl.NumberFormat().format(bizmoney) }}원 &nbsp;
          <a class="text-decoration-underline blue--text" @click="copyChargeInfo">충전하기</a>
        </p>
      </div>
    </div>

    <div class="campaign-body">
      <span style="font-size: 16px;">캠페인</span>
      <CampaignItem
          v-for="campaignId in campaignIds"
          :key="campaignId.id"
          :campaign-id="campaignId.id"
      />
      <div v-if="campaignIds.length <= 0">
        <p>아직 아무 데이터도 없습니다. 동기화를 진행하세요!</p>
      </div>
      <v-btn
          @click="paginate"
          class="more-button"
          :disabled="paginateNextAddress===null"
      >
        더보기
      </v-btn>
    </div>

    <!-- 충전 정보 복사 완료 Snackbar -->
    <v-snackbar
        v-model="isChargeInfoCopied"
    >
      가상계좌 정보가 복사되었습니다!

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="isChargeInfoCopied = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- 충전 정보 복사 실패 Snackbar -->
    <v-snackbar
        v-model="isChargeInfoFailed"
    >
      가상계좌 정보가 존재하지 않습니다. 네이버 광고 사이트를 확인하세요.

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="isChargeInfoFailed = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- 계정 동기화 Modal -->
    <v-dialog
        v-model="isModalOpen.sync"
        max-width="360"
        persistent
    >
      <v-card :loading="isSyncProgress">
        <template slot="progress">
          <v-progress-linear
              background-color="#29ABE2"
              color="#001837"
              height="10"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title class="text-h5">
          네이버 광고계정 동기화
        </v-card-title>
        <v-card-text class="gray--text">
          네이버 광고계정 내에서<br/>
          <span class="blue--text">그룹변경 작업(추가/삭제/수정)이 있을 경우</span><br/>
          계정 동기화 버튼을 클릭해주세요.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              :disabled="isSyncProgress"
              @click="executeSync"
          >
            계정 동기화
          </v-btn>
          <v-btn
              color="gray"
              text
              :disabled="isSyncProgress"
              @click="isModalOpen.sync = false"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 계정 동기화 오류 Modal -->
    <v-dialog
        v-model="isModalOpen.syncError"
        max-width="360"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          동기화 실패
        </v-card-title>
        <v-card-text class="gray--text">
          동기화 과정중 오류가 발생했습니다.<br/>
          반복적으로 이 창이 나타난다면, 관리자에게 문의해주세요.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              @click="isModalOpen.syncError = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 동기화 재시도 시간 오류 Modal -->
    <v-dialog
        v-model="isModalOpen.syncTimeLimit"
        max-width="360"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          동기화 실패
        </v-card-title>
        <v-card-text class="gray--text">
          재동기화는 <span class="blue--text">3분후</span>에 가능합니다.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              @click="isModalOpen.syncTimeLimit = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 비즈머니 소진 Modal -->
    <v-dialog
        v-model="isModalOpen.noBizMoney"
        max-width="360"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          비즈머니 소진
        </v-card-title>
        <v-card-text class="gray--text">
          비즈머니가 모두 소진되었습니다.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              @click="isModalOpen.noBizMoney = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import config from "../config";
import TopBar from "../components/top-bar";
import CampaignItem from "../components/campaign-item";
import moment from "moment";

export default {
  name: "campaign",
  components: {
    CampaignItem,
    TopBar,
  },
  data: function () {
    return {
      moment,
      exhausted: 0,
      bizmoney: 0,
      campaignIds: [],
      paginateNextAddress: null,
      isModalOpen: {
        sync: false,
        syncError: false,
        syncTimeLimit: false,
        noBizMoney: false,
      },
      isSyncProgress: false,
      isChargeInfoCopied: false,
      isChargeInfoFailed: false,
    }
  },
  methods: {
    copyChargeInfo: async function () {
      const {data: bankAccountInfo} = await this.$axios.get(`${config.NAVER_API_API_ADDRESS}markerta/naver/chargeinfo/`);
      if (bankAccountInfo.bankName === null || bankAccountInfo.accountNumber === null) {
        this.isChargeInfoFailed = true;
        return;
      }
      await this.$copyText(`${bankAccountInfo.bankName} ${bankAccountInfo.accountNumber}`);
      this.isChargeInfoCopied = true;
    },
    paginate: async function () {
      if (this.paginateNextAddress !== null) {
        const paginatedData = await this.$axios.get(this.paginateNextAddress);
        this.campaignIds = this.campaignIds.concat(paginatedData.data.results);
        this.paginateNextAddress = paginatedData.data.next;
      }
    },
    onSyncPressed: function () {
      const lastSyncTime = moment(localStorage.getItem("lastSyncTime"));
      lastSyncTime.add(3, "minutes");
      if (lastSyncTime.isAfter(moment())) {
        this.isModalOpen.syncTimeLimit = true;
      } else {
        this.isModalOpen.sync = true
      }
    },
    executeSync: async function () {
      this.isSyncProgress = true;
      try {
        this.$axios.defaults.timeout = 3000;
        await this.$axios({url: '/markerta/campaign/sync_start/', baseURL: config.SYNC_API_ADDRESS, method: "POST"});
        this.$axios.defaults.timeout = 30000;
      } catch (e) {
        console.log(e);
      }
      const intervalId = window.setInterval(async () => {
        const {status: progressCode} = await this.$axios.get("/markerta/campaign/sync_progress/")
            .catch(() => {
              this.isSyncProgress = false;
              this.isModalOpen.sync = false;
              this.isModalOpen.syncError = true;
              clearInterval(intervalId);
            });
        if (progressCode === 204) {
          localStorage.setItem("lastSyncTime", moment().toISOString());
          this.isSyncProgress = false;
          this.isModalOpen.sync = false;
          this.paginateNextAddress = config.API_ADDRESS + "markerta/campaign/";
          this.campaignIds = [];
          await this.paginate();
          clearInterval(intervalId);
        }
        console.log("Sync on progress...");
      }, 5000);
    },
  },
  mounted: async function () {
    const userId = localStorage.getItem("userId");

    // 네이버 계정이 연동되어있는가 체크
    await this.$axios.get("/markerta/naver/check/")
        .catch(async (error) => {
          console.log(error);
          const {status} = error.response;
          if (status === 400) {
            alert('아직 네이버 계정이 연동되지 않았습니다. 네이버 계정 연동 페이지로 이동합니다');
            await this.$router.push({name: "Naver"});
          }
        });

    // BizMoney 불러오기
    const loadBizMoney = async () => {
      let bizMoneyInfo;
      bizMoneyInfo = await this.$axios.get(`${config.NAVER_API_API_ADDRESS}markerta/users/${userId}/bizmoney/`);
      this.bizmoney = bizMoneyInfo.data.biz_money;
      this.exhausted = bizMoneyInfo.data.exhausted;
      if (this.bizmoney <= 0) {
        this.isModalOpen.noBizMoney = true;
      }
    }

    this.paginateNextAddress = config.API_ADDRESS + "markerta/campaign/";
    await Promise.allSettled([loadBizMoney(), this.paginate()]);

  },
}
</script>

<style scoped>
.header {
  margin-bottom: 16px;
  padding: 8px 16px 27px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  background-color: #FFFFFF;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.campaign-body {
  padding: 16px 16px 0 16px;
  background-color: #FFFFFF;
}

.cost-title {
  font-size: 16px;
  line-height: 1;
  color: #292E33;
}

.cost-date {
  font-size: 14px;
  line-height: 1;
  color: #98A5B3;
}

.cost {
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 32px;
}

.bizmoney {
  color: #6C7680;
  margin: 8px 0 0 0;
}

.more-button {
  width: 100%;
  margin: auto auto;
}
</style>
