<template>
  <v-container fill-height>
    <v-row
        align="center"
        justify="center"
    >
      <v-col>
        <v-img
            :src="require('../assets/logo2.png')"
            class="logo"
            max-height="64"
            contain
        />
        <v-form @submit.prevent="onLogin">
          아이디・이메일
          <v-text-field
              v-model="email"
              type="email"
              dense
              solo
          />
          비밀번호
          <v-text-field
              v-model="password"
              type="password"
              dense
              solo
          />
          <v-btn
              class="button blue--text"
              color="primary"
              type="submit"
              :disabled="!isInputChecked"
              depressed
              block
          >
            로그인
          </v-btn>
          <router-link to="/register">
            <v-btn
                class="button"
                color="blue"
                outlined
                block
            >
              회원가입
            </v-btn>
          </router-link>
        </v-form>
        <v-alert v-if="isLoginFailed" color="red lighten-2">
          로그인에 실패하였습니다.
          이메일, 비밀번호가 잘못되었거나 아직 관리자가 계정사용을 승인하지 않았습니다.
        </v-alert>
        <v-alert v-if="isRegisterDone" color="green lighten-2">
          회원가입이 완료되었습니다.
          관리자의 승인 이후에 로그인이 가능합니다.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'login',
  data: () => ({
    email: '',
    password: '',
    isLoginFailed: false,
  }),
  computed: {
    isInputChecked: function () {
      return this.email.length > 0 && this.password.length > 0;
    },
    isRegisterDone: function() {
      return localStorage.getItem("register-done") > 0;
    }
  },
  methods: {
    onLogin: async function () {
      try {
        // 가져온 ID와 Password를 사용하여 로그인을 시도한다.(토큰 발급 요청)
        const loginData = {
          password: this.password,
        };
        if (this.email.includes("@")) {
          loginData.email = this.email;
        }
        else {
          loginData.username = this.email;
        }
        console.log(loginData);
        const result = await this.$axios({
          method: 'post',
          url: '/accounts/login/',
          data: loginData,
          headers: {Authorization: undefined},
        });

        // 성공하였다면, 가져온 토큰을 저장한다.
        localStorage.setItem("access-token", result.data.access_token);
        localStorage.setItem("refresh-token", result.data.refresh_token);
        localStorage.setItem("userId", result.data.user.id);

        // 그리고, 메인페이지로 리다이렉팅한다.
        await this.$router.push({ name: "Campaign" });
      }
      catch (e) {
        this.isLoginFailed = true;
      }
    }
  },
}
</script>

<style scoped>
.logo {
  margin-bottom: 32px;
}

.button {
  margin-bottom: 8px;
  font-weight: bold;
}

.button.login {
  background-color: #0080FF29;
  color: #0080FF;
}

.button.login.empty {
  background-color: #DAE0E6;
  color: #98A5B3;
}

.button.register {
  color: #0080FF;
}

.logo {
  text-align: center;
  margin-bottom: 32px;
}

* {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.24px;
}
</style>
