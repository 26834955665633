<template>
  <validation-observer
      ref="observer"
      v-slot="{ invalid }"
  >
    <v-container class="pa-0">
      <top-bar title="회원가입" shadow/>
      <v-layout column>
        <v-form>
          <v-col class="pa-4">
            아이디
            <validation-provider
                v-slot="{ errors }"
                name="아이디"
                rules="required|max:20"
            >
              <v-text-field
                  v-model="username"
                  type="email"
                  label="아이디"
                  :error-messages="errors"
                  dense
                  solo
              />
            </validation-provider>
            이메일
            <validation-provider
                v-slot="{ errors }"
                name="이메일"
                rules="required|email"
            >
              <v-text-field
                  v-model="email"
                  type="email"
                  label="이메일"
                  :error-messages="errors"
                  dense
                  solo
              />
            </validation-provider>
            비밀번호
            <validation-provider
                v-slot="{ errors }"
                name="비밀번호"
                rules="required"
            >
              <v-text-field
                  v-model="password"
                  type="password"
                  label="한 개 이상의 영문자, 특수문자를 포함하여 주세요."
                  :error-messages="errors"
                  dense
                  solo
              />
            </validation-provider>
            현장명
            <validation-provider
                v-slot="{ errors }"
                name="현장명"
                rules="required|max:256"
            >
              <v-text-field
                  v-model="companyName"
                  type="text"
                  label="예) 마케르타"
                  :error-messages="errors"
                  dense
                  solo
              />
            </validation-provider>
            담당자 이름
            <validation-provider
                v-slot="{ errors }"
                name="담당자 이름"
                rules="required|max:7"
            >
              <v-text-field
                  v-model="managerName"
                  type="text"
                  label="홍길동"
                  :error-messages="errors"
                  dense
                  solo
              />
            </validation-provider>
            담당자 휴대전화
            <validation-provider
                v-slot="{ errors }"
                name="담당자 휴대전화"
                rules="required|max:256"
            >
              <v-text-field
                  v-model="phoneNumber"
                  type="tel"
                  label="01012341234"
                  :error-messages="errors"
                  dense
                  solo
              />
            </validation-provider>

            <validation-provider
                v-slot="{ errors }"
                name="이용약관 동의"
                :rules="{required: {allowFalse: false}}"
            >
              <v-checkbox
                  v-model="eulaAgreed"
                  label="이용약관에 동의"
                  :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
                v-slot="{ errors }"
                name="개인정보처리방침 동의"
                :rules="{required: {allowFalse: false}}"
            >
              <v-checkbox
                  v-model="privacyPolicyAgreed"
                  label="개인정보처리방침 동의"
                  :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col @click.prevent="signUp" align="center">
            <v-btn depressed :disabled="invalid">
              확인
            </v-btn>
          </v-col>
        </v-form>
      </v-layout>


      <!-- 회원가입 실패 Modal -->
      <v-dialog
          v-model="isRegisterFailed"
          max-width="360"
      >
        <v-card>
          <v-card-title class="text-h5">
            에러
          </v-card-title>
          <v-card-text class="gray--text">
            {{ errorMessage }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue"
                text
                @click="isRegisterFailed = false"
            >
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </validation-observer>
</template>

<script>
import TopBar from "../components/top-bar";
import {required, email, max} from "vee-validate/dist/rules";
import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from "vee-validate"

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_}는 필수 항목입니다."
});

extend("email", {
  ...email,
  message: "이메일 형식으로 입력해주세요."
})

extend("max", {
  ...max,
  message: "{_field_}는 {length}자 이하여야 합니다."
})

export default {
  name: "register",
  components: {
    TopBar,
    ValidationProvider,
    ValidationObserver,
  },
  data: function () {
    return {
      username: '',
      email: '',
      password: '',
      companyName: '',
      managerName: '',
      phoneNumber: '',
      eulaAgreed: null,
      privacyPolicyAgreed: null,
      isRegisterFailed: false,
      errorMessage: null,
    }
  },
  methods: {
    signUp: async function () {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        // 데이터를 준비합니다.
        const data = {
          username: this.username,
          email: this.email,
          password1: this.password,
          password2: this.password,
          name: this.managerName,
          phone_number: this.phoneNumber,
          company_name: this.companyName,
          manager_name: this.managerName,
        };

        // 전화번호가 0으로 시작한다면, 자동으로 이걸 없애고 +82를 앞에 붙여줍니다.
        if (data.phone_number[0] === '0') {
          data.phone_number = '+82' + data.phone_number.slice(1, data.phone_number.length);
        }

        // 서버에 회원가입을 요청합니다.
        // let result;
        try {
          await this.$axios({
            method: 'post',
            url: '/accounts/integrate/register/?account_type=2',
            data,
            headers: {Authorization: undefined},
          })
          // await this.$axios.post(
          //     "/accounts/integrate/register/?account_type=2", data
          // );
          // result = await this.$axios.post(
          //     "/accounts/integrate/register/?account_type=2", data
          // );
        } catch (error) {
          // 저런! 뜨거운 에러가 발생했어요.

          // axios 리스폰스를 그대로 받아서, 적절한 에러메세지를 string 형태로 리턴합니다. (null/undefined를 리턴하지 않음)
          const takeErrorMessages = (resp) => {
            // 리스폰스 에러가 아니라 네트워크 등의 에러가 발생했다면 여기서 걸립니다.
            if (resp == null) { return "알 수 없는 에러"; }

            // 아니라면 여러 에러 메세지중 가장 첫번째 것을 리턴합니다.
            // 아래의 코드는 🤗정말 더러우니🤗 읽으려고 하지 마세요.
            // eslint-disable-next-line no-unused-vars
            const mapper = ([_, value]) => value;
            const nested = Object.entries(resp).map(mapper);
            const nested2 = nested.flat();

            if (nested2.length !== 0) { console.log(nested2[0]); return nested2[0]; }
            else { return "알 수 없는 에러"; }
          };

          // 에러가 발생했다는 것을 알리고, 에러메세지를 지정합니다. 그럼 이게 렌더링될거예요.
          this.isRegisterFailed = true;
          this.errorMessage = takeErrorMessages(error?.response?.data);
          return;
        }

        // 성공적으로 회원가입되었다면 토큰 정보를 저장합니다.
        // localStorage.setItem("access-token", result.data.access_token);
        // localStorage.setItem("refresh-token", result.data.refresh_token);
        // localStorage.setItem("userId", result.data.user.id);

        // 관리자가 승인을 해야 쓸 수 있기 때문에, 메인페이지가 아니라 로그인 페이지로 리다이렉팅해줍니다.
        localStorage.setItem("register-done", "1");
        await this.$router.push({ name: "Login", params: { registerComplete: true, }});
      }
    },
  },
}
</script>

<style scoped>

</style>
