<template>
  <v-container class="pa-0">
    <top-bar title="캠페인" hide-back my shadow/>
    <div class="pa-4">
      <h2>네이버 광고 계정을 등록해주세요</h2>
      <p class="gray--text">네이버 광고 계정을 등록해<br/>보다 효율적으로 입찰해보세요.</p>

      <span class="gray--text">네이버 광고 아이디</span>
      <v-text-field
          v-model="naverId"
          type="text"
          dense
          solo
      />
      <span class="gray--text">네이버 광고 비밀번호</span>
      <v-text-field
          v-model="naverPassword"
          type="password"
          dense
          solo
      />
      <div class="d-flex justify-center">
        <v-btn
            @click="appendAccount"
            depressed
        >
          확인
        </v-btn>
      </div>
    </div>

    <!-- 광고계정 연동 성공 Modal -->
    <v-dialog
        v-model="isLoginSuccess"
        max-width="360"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          광고계정 연동 성공
        </v-card-title>
        <v-card-text class="gray--text">
          광고계정이 연동되었습니다.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              @click="onSuccess"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 광고계정 연동 실패 Modal -->
    <v-dialog
        v-model="isLoginFail"
        max-width="360"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          광고계정 연동 실패
        </v-card-title>
        <v-card-text class="gray--text">
          광고계정 연동에 실패했습니다.<br/>
          네이버 광고 ID, 비밀번호를 다시 확인해주세요.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              @click="onFail"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 광고계정 API키 취득 실패 Modal -->
    <v-dialog
        v-model="isAPIKeyNotFound"
        max-width="360"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          광고계정 연동 실패
        </v-card-title>
        <v-card-text class="gray--text">
          네이버 API키가 발급되지 않았습니다.<br/>
          네이버 API키를 발급해주세요.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              @click="onFail"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TopBar from "../components/top-bar";
import config from "../config";

export default {
  name: "naver-account-management",
  components: {
    TopBar,
  },
  data: function () {
    return {
      naverId: '',
      naverPassword: '',
      loginStatus: 0,
    };
  },
  methods: {
    appendAccount: async function () {
      try {
        const {status} = await this.$axios.post(
            `${config.NAVER_API_API_ADDRESS}markerta/naver/`, {
              naver_id: this.naverId,
              naver_password: this.naverPassword,
            }
        );
        this.loginStatus = status;
      } catch (e) {
        this.loginStatus = e.response.status;
      }
    },
    onSuccess: async function () {
      await this.$router.push({name: "Campaign"});
    },
    onFail: function () {
      this.loginStatus = 0;
    }
  },
  created: async function () {
    try {
      await this.$axios.get("/markerta/naver/check/");
      await this.$router.push({name: "Campaign"});
    } catch {
      console.log("keep going");
    }
  },
  computed: {
    isLoginSuccess: function () {
      return this.loginStatus === 200;
    },
    isLoginFail: function () {
      return this.loginStatus === 401;
    },
    isAPIKeyNotFound: function() {
      return this.loginStatus === 404;
    }
  }
}
</script>

<style scoped>

</style>