import Vue from "vue";
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

const FontAwesome = {
    install(Vue) {
        library.add(faAngleLeft);
        Vue.component('font-awesome-icon', FontAwesomeIcon);
    }
}

Vue.use(FontAwesome);

export default FontAwesome;