<template>
  <v-container class="pa-0">
    <top-bar title="마이페이지" shadow/>
    <div class="my-page-body">
      <div>
        <div class="user-data-body">
          <div class="user-data">
            <p>아이디</p>
            <v-textarea solo readonly rows="1" v-model="email"/>
          </div>
          <div class="user-data">
            <p>비밀번호</p>
            <v-textarea solo readonly rows="1" value="********"/>
          </div>
          <div class="user-data">
            <p>회사명</p>
            <v-textarea solo readonly rows="1" v-model="companyName"/>
          </div>
          <div class="user-data">
            <p>담당자 이름</p>
            <v-textarea solo readonly rows="1" v-model="managerName"/>
          </div>
          <div class="user-data">
            <p>담당자 휴대전화</p>
            <v-textarea solo readonly rows="1" v-model="phoneNumber"/>
          </div>
        </div>
        <v-btn
            block color="primary"
            class="setting-button blue--text"
            @click="onAdAccount"
            depressed
        >
          네이버 광고계정 관리하기
        </v-btn>
        <v-btn
          block color="primary"
          class="setting-button blue--text"
          @click="onIllegalClick"
          depressed
        >
          부정클릭 방지
        </v-btn>
      </div>
      <v-col align="center">
        <v-btn text @click="onLogout">
          로그아웃
        </v-btn>
      </v-col>
    </div>

    <!-- 부정클릭방지 스크립트 표시용 Modal -->
    <v-dialog
        v-model="modalOpen.illegalClick"
        max-width="360"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          부정클릭 방지 안내
        </v-card-title>
        <v-card-text class="gray--text">
          웹사이트의 <code>&lt;body/&gt;</code> 태그 바로 위에 아래의 소스코드를 붙여넣어주세요.<br/>
          <code>
            &lt;script src="{{illegalClickScriptUrl}}"&gt;&lt;/script&gt;
          </code>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue"
              text
              @click="modalOpen.illegalClick = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 로딩중 Overlay -->
    <v-overlay :value="isLoading">
      <v-progress-circular
          :size="100"
          :width="10"
          color="purple"
          indeterminate
      />
    </v-overlay>

  </v-container>
</template>

<script>
import Config from "../config"
import TopBar from "../components/top-bar";

export default {
  name: "my-page-2",
  components: {
    TopBar,
  },
  data: function () {
    return {
      isLoading: true,
      email: 'test@test.com',
      companyName: '이브로드TV',
      managerName: '전석재',
      phoneNumber: '01034056391',
      illegalClickUserToken: "",
      modalOpen: {
        illegalClick: false,
      }
    }
  },
  computed: {
    illegalClickScriptUrl: function() {
      return `${Config.API_ADDRESS}markerta/js/?user=${this.illegalClickUserToken}`;
    },
  },
  methods: {
    onAdAccount: async function () {
      await this.$router.push({name: 'AdAccount'});
    },

    // todo Refresh token was not included in request data. 뜨는거 고치기
    onLogout: async function () {
      const result = await this.$axios.post(
          "/accounts/logout/", {
            access: localStorage.getItem("access-token"),
            refresh: localStorage.getItem("refresh-token"),
          }
      );
      console.log("Logout");
      console.log(result);
      if (result.status !== 200) {
        throw "logout failed!";
      }
      localStorage.clear();
      await this.$router.push({ name: "Login" });
    },

    onIllegalClick: async function() {
      this.isLoading = true;
      const tokenResult = await this.$axios.post("/accounts/api-token-auth/");
      this.illegalClickUserToken = tokenResult.data.token;

      this.isLoading = false;
      this.modalOpen.illegalClick = true;
    },

    copyIllegalClickScript: async function() {
      const script = `<script src="${this.illegalClickScriptUrl}"></` + 'script>';
      await this.$copyText(script);
      console.log("copied! -> " + script);
    },
  },
  created: async function() {
    const userInfo = await this.$axios.get("/accounts/user/", { withCredentials: false });
    this.email = userInfo.data.email;
    this.companyName = userInfo.data.company_name;
    this.phoneNumber = userInfo.data.phone_number;
    this.managerName = userInfo.data.manager_name;
    this.isLoading = false;
  }
}
</script>

<style scoped>
.setting-button {
  margin: 16px 0;
}

.my-page-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: space-between;
  padding: 16px;
  height: 100%;
}

.user-data-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: space-between;
}

.user-data > p {
  margin: 0;

}
</style>