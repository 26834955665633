<template>
  <v-container class="pa-0">
    <top-bar title="그룹별 시간 설정" shadow/>
    <div v-for="bidTimeInfo in bidTimeInfos" :key="bidTimeInfo.rate" class="bidtime-container">
      <h3 class="rate">{{ bidTimeInfo.rate }}등급</h3>
      <div class="bidtime-setting-container">
        <span class="gray--text">반복입찰 시간설정</span>
        <div class="d-flex justify-end align-center">
          <v-text-field v-model="bidTimeInfo.hour" outlined hide-details reverse dense style="width:50px;"/>&nbsp;시간&nbsp;
          <v-text-field outlined v-model="bidTimeInfo.minute" hide-details reverse dense style="width:50px;"/>&nbsp;분
        </div>
      </div>
      <v-btn color="primary" class="save blue--text" @click="onSave(bidTimeInfo)" block depressed>변경사항 저장</v-btn>
    </div>
    <!-- 저장 완료 Snackbar -->
    <v-snackbar
        v-model="isSaveSuccess"
    >
      {{ currentBidRate }}등급의 변경사항을 저장했습니다.

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="isSaveSuccess = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- 저장 실패 Snackbar -->
    <v-snackbar
        v-model="isSaveFailed"
    >
      {{ currentBidRate }}등급의 변경사항 저장에 실패했습니다. 관리자에게 문의해주세요.

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="isSaveFailed = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TopBar from "../components/top-bar";

export default {
  name: "bidtime-setting",
  components: {
    TopBar,
  },
  data: function () {
    return {
      currentBidRate: 0,
      isSaveSuccess: false,
      isSaveFailed: false,
      bidTimeInfos: [
        {
          rate: 1,
          hour: 0,
          minute: 3,
          pk: 0,
          isExistOnServer: false,
        },
        {
          rate: 2,
          hour: 0,
          minute: 3,
          pk: 0,
          isExistOnServer: false,
        },
        {
          rate: 3,
          hour: 0,
          minute: 30,
          pk: 0,
          isExistOnServer: false,
        }
      ]
    }
  },
  methods: {
    onSave: async function (info) {
      this.currentBidRate = info.rate;
      const sendData = {
        campaign_id: parseInt(sessionStorage.getItem("campaign-pk")),
        bid_time: `${info.hour.toString().padStart(2, "0")}:${info.minute.toString().padStart(2, "0")}:00`,
        rate: info.rate,
      };

      try {
        if (info.isExistOnServer)
          await this.$axios.put(`/markerta/bidtime/${info.pk}/`, sendData)
        else {
          const response = await this.$axios.post("/markerta/bidtime/", sendData);
          info.isExistOnServer = true;
          console.log(response);
          info.pk = response.data.pk;
        }
        this.isSaveSuccess = true;
      } catch (e) {
        this.isSaveFailed = true;
      }
    }
  },
  created: async function () {
    const {data} = await this.$axios.get(`/markerta/bidtime/?campaign=${sessionStorage.getItem("campaign-pk")}`);
    console.log(data);

    data.results.forEach(bidTimeInfo => {
      const duration = bidTimeInfo.bid_time.split(":");
      this.bidTimeInfos[bidTimeInfo.rate - 1].hour = parseInt(duration[0]);
      this.bidTimeInfos[bidTimeInfo.rate - 1].minute = parseInt(duration[1]);
      this.bidTimeInfos[bidTimeInfo.rate - 1].pk = bidTimeInfo.pk;
      this.bidTimeInfos[bidTimeInfo.rate - 1].isExistOnServer = true;
    });
  }
}
</script>

<style scoped>
.bidtime-container {
  margin: 16px;
  padding: 16px;
  background-color: #FFFFFF;
}

.rate {
  margin-bottom: 16px;
  font-weight: 400;
}

.bidtime-setting-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.save {
  margin-top: 16px;
}
</style>