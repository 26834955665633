<template>
  <div class="multiple-change-container">
    <div class="multiple-input">
      <label>전체 선택</label>
      <v-switch v-model="isSelectedAll" />
    </div>
    <div class="multiple-input">
      <label>작동 여부</label>
      <v-switch v-model="multipleValues.isBidding" color="green" hide-details />
    </div>
    <div class="multiple-input">
      <label>목표 순위</label>
      <v-text-field
          dense
          reverse
          outlined
          hide-details
          type="number"
          class="keyword-input-field"
          :disabled="!multipleValues.isBidding"
          v-model="multipleValues.targetPosition"
      />
    </div>
    <div class="multiple-input">
      <label>최대 가격</label>
      <v-text-field
          dense
          reverse
          outlined
          hide-details
          ref="maxBidding"
          class="keyword-input-field"
          :disabled="!multipleValues.isBidding"
          v-model="multipleValues.maxBidding"
      />
    </div>
    <div class="multiple-input">
      <label>가중치</label>
      <v-text-field
          dense
          reverse
          outlined
          hide-details
          ref="weight"
          class="keyword-input-field"
          :disabled="!multipleValues.isBidding"
          v-model="multipleValues.weight"
      />
    </div>
  </div>
</template>

<script>
import AutoNumeric from "autonumeric";

export default {
  name: "keyword-multiple-editor",
  model: {
    prop: "multipleValues",
    event: "change"
  },
  props: ["multipleValues"],
  data: function() {
    return {
      isSelectedAll: false,
    }
  },
  mounted: async function() {
    new AutoNumeric(this.$refs.maxBidding.$refs.input, {
      decimalPlaces: 0,
      maximumValue: 100000,
      formatOnPageLoad: true,
    });
    new AutoNumeric(this.$refs.weight.$refs.input, {
      decimalPlaces: 0,
      maximumValue: 100000,
      formatOnPageLoad: true,
    });
  },
  watch: {
    isSelectedAll: function(toggleValue) {
      this.$emit('selectAllClicked', toggleValue);
    },
  }
}
</script>

<style scoped>

.multiple-change-container {
  display: flex;
  flex-direction: row;
}

.multiple-change-container .multiple-input {
  flex: 1;
  padding: 8px;
}
</style>