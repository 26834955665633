<template>
  <v-container class="pa-0">
    <top-bar title="광고계정 관리"/>
    <div class="ad-account-cont">
      <div class="ad-account-display-cont">
        <label>현재 로그인되어있는 네이버 광고계정</label>
        <p>{{ username }}</p>
      </div>
      <v-col align="center">
        <v-btn
            color="primary"
            class="blue--text"
            @click="warningModalOpen = true"
        >
          광고계정 로그아웃
        </v-btn>
      </v-col>
    </div>

    <!-- 광고계정 로그아웃 경고 Modal -->
    <v-dialog
        v-model="warningModalOpen"
        max-width="360"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          경고
        </v-card-title>
        <v-card-text class="gray--text">
          정말로 로그아웃하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="gray"
              text
              @click="warningModalOpen = false"
          >
            아니오
          </v-btn>
          <v-btn
              color="blue"
              text
              @click="adAccountLogout"
          >
            예
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TopBar from "../components/top-bar";

export default {
  name: "ad-account-management",
  components: {
    TopBar
  },
  data: function () {
    return {
      naverInfoId: -1,
      username: '',
      password: '',
      warningModalOpen: false,
    }
  },
  methods: {
    adAccountLogout: async function () {
      await this.$axios.post(`/markerta/naver/${this.naverInfoId}/unlink/`);
      await this.$router.push({name: "Naver"});
    }
  },
  created: async function () {
    const {data: accountInfo} = await this.$axios.get(`/markerta/naver/`);
    this.naverInfoId = accountInfo[0].id;
    this.username = accountInfo[0].naver_id;
    this.password = accountInfo[0].naver_password;
  }
}
</script>

<style scoped>
.ad-account-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ad-account-display-cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 8px;
}

.ad-account-display-cont p {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
}

</style>