import Vue from "vue";
import VueRouter from "vue-router";

/*
--- View files import ---
 */
import IndexView from "./views";
import NotFoundView from "./views/errros/not-found";
import LoginView from "./views/login";
import RegisterView from "./views/register";
import CampaignView from "./views/campaign";
import AdGroupView from "./views/adgroup";
import BidSettingView from "./views/bidtime-setting";
import KeywordView from "./views/keyword";
import MyPageView from "./views/my-page";
import AdAccountView from "./views/ad-account-management";
import NaverAccountView from "./views/register-naver";

/*

/*
--- Authentication check function ---
 */
const requireAuth = (from, to, next) => {
  const accessToken = localStorage.getItem("access-token");
  if (accessToken) {
    return next();
  }
  next("/login");
};

/*
--- Add routes here ---
 */
Vue.use(VueRouter);
const route = [
  {
    path: "/",
    component: IndexView,
  },
  {
    name: "NotFound",
    path: "*",
    component: NotFoundView,
  },
  {
    name: "Login",
    path: "/login",
    component: LoginView,
    props: true,
  },
  {
    name: "Register",
    path: "/register",
    component: RegisterView,
  },
  {
    name: "Naver",
    path: "/register/naver",
    component: NaverAccountView,
    beforeEnter: requireAuth,
  },
  {
    name: "Campaign",
    path: "/campaign",
    component: CampaignView,
    beforeEnter: requireAuth,
  },
  {
    name: "Adgroup",
    path: "/adgroup",
    component: AdGroupView,
    beforeEnter: requireAuth,
  },
  {
    name: "BidTime",
    path: "/adgroup/bidtime",
    component: BidSettingView,
    beforeEnter: requireAuth,
  },
  {
    name: "Keyword",
    path: "/keyword",
    component: KeywordView,
    beforeEnter: requireAuth,
  },
  {
    name: "User",
    path: "/profile",
    component: MyPageView,
    beforeEnter: requireAuth,
  },
  {
    name: "AdAccount",
    path: "/profile/account",
    component: AdAccountView,
    beforeEnter: requireAuth,
  },
];

// --------------------
const router = new VueRouter({
  mode: "history",
  routes: route,
});

export default router;
