<template>
  <v-container fill-height fluid>
    <h1>Not found</h1>
  </v-container>
</template>

<script>
export default {
  name: "404",
}
</script>

<style scoped>
</style>