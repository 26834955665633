<template>
  <div class="keyword-container">
    <div class="header">
      <div class="d-flex flex-row align-center">
        <v-checkbox v-model="keywordData.selected" v-if="editMode === 'multiple'"/>
        <p class="keyword">{{ keywordData.keyword }} <v-icon v-if="keywordData.isLimited" @click="snackbar=true">mdi-lock</v-icon></p>
        <v-snackbar
            v-model="snackbar"
            color="error"
        >
          해당 키워드는 키워드 제한 갯수초과로 자동 비딩이 되지 않습니다. 관리자에게 문의해주세요 :)

          <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <div class="d-flex flex-row align-center">
        입찰여부
        <v-switch
            v-model="keywordData.isBidding"
            color="green"
            style="margin: 0"
            hide-details
        />
      </div>
    </div>
    <div class="body">
      <div class="body-info">
        <p class="info-title">노출순위</p>
        <div class="info-body">
          <span v-if="!keywordData.isActive || !keywordData.isBidding">수집되지 않음</span>
          <span v-else-if="currentPosition === '-' && slots === '-'">현재 슬롯이 없음</span>
          <span v-else>현재: {{ currentPosition }}/{{ slots }}위</span>
          <div class="d-flex flex-row align-center">
            목표:
            <v-text-field
                dense
                reverse
                outlined
                hide-details
                type="number"
                class="keyword-input-field"
                :disabled="!keywordData.isBidding"
                v-model="keywordData.targetPosition"
            />
            위
          </div>
        </div>
      </div>
      <div class="body-info">
        <p class="info-title">입찰가격</p>
        <div class="info-body">
          <span>현재: <span class="font-weight-bold">{{ keywordData.bidding }}</span>원</span>
          <div class="d-flex flex-row align-center">
            최대:
            <v-text-field
                dense
                reverse
                outlined
                hide-details
                ref="maxBidding"
                class="keyword-input-field"
                :disabled="!keywordData.isBidding"
                v-model="keywordData.maxBidding"
            />
            원
          </div>
        </div>
      </div>
      <div class="body-info">
        <p class="info-title">가중값</p>
        <div class="info-body">
          <span></span>
          <div class="d-flex flex-row align-center">
            <v-text-field
              dense
              reverse
              outlined
              hide-details
              class="keyword-input-field"
              ref="weight"
              :disabled="!keywordData.isBidding"
              v-model="keywordData.weight"
            />
            원
          </div>
        </div>
      </div>
      <div class="body-info">
        <p>최근 입찰 시간: <span>{{ updatedAtString }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import AutoNumeric from "autonumeric";
import moment from "moment";

export default {
  name: "keyword-item",
  props: ["keywordData", "mobile", "editMode"],
  mounted: async function () {
    new AutoNumeric(this.$refs.maxBidding.$refs.input, {
      decimalPlaces: 0,
      maximumValue: 100000,
      formatOnPageLoad: true,
    });
    new AutoNumeric(this.$refs.weight.$refs.input, {
      decimalPlaces: 0,
      maximumValue: 100000,
      formatOnPageLoad: true,
    });
  },
  data(){
    return {
      snackbar: false,
      isEnabled: !this.keywordData.isLimited && this.keywordData.isBidding
    }
  },
  computed: {
    updatedAtString: function () {
      if (!this.keywordData.lastUpdatedAt) {
        return "없음";
      }
      return moment(this.keywordData.lastUpdatedAt).fromNow();
    },
    currentPosition: function () {
      return (this.keywordData.currentPosition === -1 ? "-" : this.keywordData.currentPosition);
    },
    slots: function () {
      return (this.keywordData.slots <= 0 ? "-" : this.keywordData.slots);
    }
  },
}
</script>

<style scoped>
.keyword-container {
  background-color: #FFFFFF;
  margin: 16px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 100%;
  max-width: 768px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.header .keyword {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.body {
  margin-top: 32px;
}

.body .body-info .info-title {
  font-size: 15px;
  font-weight: bold;
  margin: 0;
}

.body .body-info .info-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.body .body-info .keyword-input-field {
  max-width: 115px;
}

</style>