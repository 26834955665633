<template>
  <div class="campaign-card">
    <div @click="adGroupSelected">
      <p class="ma-0 campaign-name">
        캠페인&nbsp;
        <span class="blue--text">{{ properties.campaign_id.name }}</span>
      </p>
      <p class="ma-0 gray--text campaign-type">파워링크</p>
    </div>
    <v-switch
        v-model="properties.is_active"
        @change="onActiveChange"
        style="margin: 0;"
        color="green"
        hide-details
    />
  </div>
</template>

<script>

export default {
  name: "campaign-item",
  props: ["campaignId"],
  data: function() {
    return {
      properties: {
        campaign_id: {},
      },
    }
  },
  mounted: async function() {
    const campaignResponse = await this.$axios.get(`/markerta/campaign/${this.campaignId}/`);
    this.properties = campaignResponse.data;
  },
  methods: {
    onActiveChange: async function(value) {
      await this.$axios.patch(`/markerta/campaign/${this.properties.id}/`, {
        is_active: value,
      })
    },
    adGroupSelected: async function () {
      sessionStorage.setItem("campaign-name", this.properties.campaign_id.name);
      sessionStorage.setItem("campaign-id", this.properties.campaign_id.ncc_campaign_id);
      sessionStorage.setItem("campaign-option-id", this.properties.id);
      sessionStorage.setItem("campaign-pk", this.properties.campaign_id.id);
      sessionStorage.setItem("campaign-is-active", this.properties.is_active);
      await this.$router.push({name: "Adgroup"});
    },
  }
}
</script>

<style scoped>
.campaign-card {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.campaign-card .campaign-name {
  font-size: 14px;
  line-height: 18px;
}

.campaign-card .campaign-type {
  font-size: 10px;
  line-height: 12px;
  margin-top: 8px !important;
}
</style>