<template>
  <div :class="adGroupClass">
    <div class="adgroup-core-container">
      <div class="adgroup-item">
        <div class="adgroup-info-container">
          <div class="adgroup-info">
            <v-select solo dense v-model="properties.bid_rank" :items="rates" @change="changeBidRank" hide-details
                      style="width: 100px;" :disabled="isErrorOccurred"/>
            <AdGroupDisplayTypeChip v-if="!isErrorOccurred" class="display-target" :display-target="displayTarget"/>
          </div>
          <span :class="adgroupName" @click="adGroupSelected">{{ displayName }}</span>
          <v-tooltip bottom v-if="isErrorOccurred">
            <template v-slot:activator="{ on, attrs }">
              <span class="error-icon" @click="$emit('errorIconClicked', occurredErrors)" v-bind="attrs" v-on="on"/>
            </template>
            <span>클릭하시면 문제점과 해결 방법을 확인하실 수 있습니다.</span>
          </v-tooltip>
        </div>
      </div>
      <div class="adgroup-toggle-container">
        <v-switch v-model="properties.is_active" @change="changeActive" color="green"
                  :disabled="isErrorOccurred"/>
      </div>
    </div>
    <p class="gray--text">파워링크 > 광고그룹</p>
    <v-divider/>
  </div>
</template>

<script>
import AdGroupDisplayTypeChip from "./adgroup-display-type-chip";
import 'moment/locale/ko';

export default {
  name: "adgroup-items",
  props: ["properties"],
  components: {
    AdGroupDisplayTypeChip,
  },
  data: function () {
    return {
      displayName: "",
      prefix: "",
      rates: [
        {
          text: "1등급",
          value: 1
        },
        {
          text: "2등급",
          value: 2
        },
        {
          text: "3등급",
          value: 3
        },
      ]
    }
  },
  methods: {
    adGroupSelected: async function () {
      if (!this.isErrorOccurred) {
        sessionStorage.setItem("selected-adgroup", JSON.stringify(this.properties.relationships));
        sessionStorage.setItem("adgroup-display-target", this.displayTarget);
        await this.$router.push({name: "Keyword"});
      }
    },
    changeBidRank: async function () {
      await this.$axios.patch(`/markerta/adgroup/${this.properties.relationships.related_adgroup_option}/`, {bid_rank: this.properties.bid_rank});
      await this.$axios.patch(`/markerta/adgroup/${this.properties.relationships.origin_adgroup_option}/`, {bid_rank: this.properties.bid_rank});
    },
    changeActive: async function () {
      if (this.properties.relationships.origin_adgroup_option) {
        await this.$axios.patch(`/markerta/adgroup/${this.properties.relationships.origin_adgroup_option}/`, {is_active: this.properties.is_active});
      }
      if (this.properties.relationships.related_adgroup_option) {
        await this.$axios.patch(`/markerta/adgroup/${this.properties.relationships.related_adgroup_option}/`, {is_active: this.properties.is_active});
      }
    },
  },
  computed: {
    displayTarget: function() {
      if (this.properties.relationships.related_adgroup_option !== null) {
        return "A";
      }
      return this.properties.adgroup_id.display_target;
    },
    // Prefix와 노출 매체 세팅이 틀린 경우
    isPrefixMismatched: function () {
      return this.prefix === "PC" && this.properties.adgroup_id.display_target === "M" ||
          this.prefix === "모바일" && this.properties.adgroup_id.display_target === "P";
    },
    // Prefix가 안 붙어있는 경우
    isPrefixMissing: function () {
      return this.prefix === undefined;
    },
    // 광고그룹 이름에 언더바가 들어가서 Prefix를 인식하지 못한 경우
    isPrefixWrong: function() {
      return this.prefix !== "PC" && this.prefix !== "모바일";
    },
    // 노출 매체가 "PC + 모바일" 로 설정되어있는 경우
    isDisplayTargetSettedAll: function() {
      return this.properties.adgroup_id.display_target === "A";
    },
    adgroupName: function () {
      if (this.isErrorOccurred) {
        return "adgroup-name adgroup-name-error";
      }
      return "adgroup-name adgroup-name-normal";
    },
    adGroupClass: function() {
      if (this.isErrorOccurred) {
        return "adgroup adgroup-error";
      }
      return "adgroup";
    },
    isErrorOccurred: function() {
      const errors = Object.values(this.occurredErrors);
      return errors.includes(true);
    },
    occurredErrors: function() {
      return {
        isPrefixMissing: this.isPrefixMissing,
        isPrefixMismatched: this.isPrefixMismatched,
        isPrefixWrong: this.isPrefixWrong,
        isDisplayTargetSettedAll: this.isDisplayTargetSettedAll,
      };
    },
  },
  mounted: function () {
    const dividedName = this.properties.adgroup_id.name.split("_");
    this.displayName = dividedName[0];
    this.prefix = dividedName[1];
  }
}
</script>

<style scoped>
.adgroup {
  border-radius: 10px;
  margin: 16px 0;
}

.adgroup-error {
  color: #9e9e9e;
}

.adgroup-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.adgroup-core-container {
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
}

.adgroup-info-container {
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.adgroup-info {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.adgroup-name, .display-target {
  margin-left: 16px;
}

.adgroup-name-normal {
  color: #0080FF;
}

.adgroup-name-error {
  color: #0080FF52;
}

.adgroup-toggle-container {
  display: inline-block;
}

.error-icon {
  content: url("../assets/warning.svg");
  margin-left: 8px;
}

@media screen and (max-width: 480px) {
  .adgroup-info-container {
    flex-wrap: wrap;
  }

  .adgroup-name {
    margin: 16px 16px 16px 0px;
    display: block;
  }
}
</style>