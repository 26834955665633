<template>
  <v-row :class="topBarClass" justify="center" align="center">
    <v-col class="col-2 pa-0">
      <v-btn
          color="black"
          v-if="!hideBack"
          plain
          @click="previousPage"
      >
        <font-awesome-icon icon="angle-left" size="2x" />
      </v-btn>
    </v-col>
    <v-col class="col-8 text-center pa-0">
      <h3 class="topbar-text">{{ title }}</h3>
    </v-col>
    <v-col class="col-2 pa-0 d-flex flex-row-reverse">
      <v-btn
          class="topbar-text"
          color="black"
          v-if="my"
          plain
          @click="myPage"
      >
        MY
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "top-bar",
  props: {
    hideBack: {
      type: Boolean,
      default: false,
    },
    title: String,
    my: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    previousPage: function () {
      this.$router.go(-1);
    },
    myPage: async function () {
      await this.$router.push({name: "User"});
    }
  },
  computed: {
    topBarClass: function () {
      const topBarClassName = "top-bar";
      return (this.shadow) ? topBarClassName + " shadow" : topBarClassName;
    }
  }
}
</script>

<style scoped>
.top-bar {
  background-color: #FFFFFF;
  margin: 0;
  height: 48px;
  min-height: 48px;
}

.top-bar.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
}

.topbar-text {
  font-weight: 700;
  font-size: 16px;
}
</style>