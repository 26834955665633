<template>
  <div/>
</template>

<script>
export default {
  name: "index",
  created: async function() {
    const accessToken = localStorage.getItem("access-token");
    if (accessToken) {
      await this.$router.push({ name: "Campaign" });
    }
    else {
      await this.$router.push({ name: "Login" });
    }
  }
}
</script>